/** @jsxImportSource theme-ui */
import React from "react";
import { Container, Themed } from "theme-ui";

type Props = {
  html: string;
  sxpass?: object;
  [props: string]: any;
};

const ThemedMarkdown = ({ html, sxpass, ...props }: Props) => {
  return (
    <div sx={{ variant: "styles", width: "100%" }} {...props}>
      <Themed.root dangerouslySetInnerHTML={{ __html: html }} sx={{ ...sxpass }} />
    </div>
  );
};

export const ThemedMarkdownLight = ({ html, sxpass, ...props }: Props) => {
  return (
    <div sx={{ variant: "styles", width: "100%" }} {...props}>
      <Container
        variant="themedLightRoot"
        dangerouslySetInnerHTML={{ __html: html }}
        sx={{ ...sxpass }}
      />
    </div>
  );
};

export default ThemedMarkdown;
